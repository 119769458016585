import React from 'react';
import EpLogo from './EpLogo';
import Routes from './Routes';

const Layout = ({ children }) => {
  return (
    <>
      <header>
        <div className="logo">
          <EpLogo />
        </div>
      </header>
      <Routes />
      <footer>
        <div className="copyright">{`© ${new Date().getFullYear()} e+p films GmbH`}</div>
      </footer>
    </>
  );
};

export default Layout;
